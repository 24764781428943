<template>
  <div class='mobile-input'>
    <template v-if='label'>
      <div class='lab'
        :class='{ "is-invalid": error }'>
        <div class='ovf'>{{ label }}</div>
      </div>
    </template>
    <div class='dfx'
      :class='{ "is-invalid": error }'>
      <select-element class='sel'
        :placeholder='placeholder?.match(/^\((.*)\)/)?.[1]'
        :disabled='disabled'
        :options='callingCodeOptions'
        :model-value='code'
        @focus='$emit("focus", $event)'
        @blur='$emit("blur", $event)'
        @update:model-value='updateValue($event, number)'>
        <template v-slot:option='{ option }'>
          <div>{{ option.text.split(' ')[0] }}</div>
          <div class='nop'>{{ option.text.split(' ')[1] }}</div>
        </template>
      </select-element>
      <input-element class='iel'
        type='text'
        :placeholder='placeholder?.replace(/^\(.*\)/, "")'
        :disabled='disabled'
        :model-value='number'
        @focus='$emit("focus", $event)'
        @blur='$emit("blur", $event)'
        @update:model-value='updateValue(code, $event)'/>
    </div>
    <template v-if='error'>
      <div class='err'>
        <div class='ovf'>{{ error }}</div>
      </div>
    </template>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import callingCodes from './calling-codes.json'

  export default {
    props: {
      label: String,
      placeholder: String,
      error: String,
      disabled: Boolean,
      modelValue: String
    },

    emits: [
      'focus',
      'blur',
      'update:modelValue'
    ],

    setup (props, context) {
      let callingCodeOptions = callingCodes
        .map(({ code, country }) => ({
          text: `${country} +${code}`,
          value: code
        }))

      let code = computed(() =>
        props.modelValue.split(' ')[0].slice(1)
      )

      let number = computed(() =>
        props.modelValue.split(' ')[1] ?? ''
      )

      let updateValue = (code, number) => {
        context.emit('update:modelValue', `+${code} ${number}`)
      }

      return {
        callingCodeOptions,
        code,
        number,
        updateValue
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .lab {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'gray', '500');
    font-size: map.get(variables.$font-sizes, 'sm');

    &.is-invalid {
      color: map.get(variables.$colors, 'red', '500');
    }
  }

  .ovf {
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dfx {
    display: flex;

    &.is-invalid {
      &::v-deep(.int),
      &::v-deep(.inp) {
        border-color: map.get(variables.$colors, 'red', '300');
        color: map.get(variables.$colors, 'red', '500');

        &:focus,
        &:hover {
          border-color: map.get(variables.$colors, 'red', '500');
        }
      }
    }
  }

  .sel {
    flex-shrink: 0;
    width: 120px;

    &::v-deep(.opt) {
      justify-content: space-between;
    }
  }

  .nop {
    color: map.get(variables.$colors, 'gray', '500');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  .iel {
    margin-left: 16px;
    width: 100%;
  }

  .err {
    display: flex;
    align-items: center;
    margin-top: 8px;
    height: 16px;
    color: map.get(variables.$colors, 'red', '500');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .lab {
      height: 20px;
    }

    .sel {
      width: 130px;
    }

    .err {
      height: 20px;
    }
  }
</style>
